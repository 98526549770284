<template>
    <div class="">
        <div class="">
            <custom-alert v-if="displayAlert==true" :message="alertMessage"
                          v-on:showedAlert="resetAlert"></custom-alert>
            <div class="page-banner">
                 <img :src="this.getSiteProps('clubinnerpages.headerbanner')" style="width:100%;" alt="page-banner">
                <div class="page-title">
                    <h5 class="text-white"> Member Donation</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 mb-4 mt-4">
                    <div class=" card card-body  pt-3 pb-3">
                        <b-overlay :show="showLoader" no-wrap></b-overlay>
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="float-left">Support your sport? </h4>
                                <b-button class="float-right" v-b-modal.donation-modal> Add Donation</b-button>
                            </div>

                            <div class="col-md-12 mt-3">
                                <b-card no-body >
                                    <div class="row">
                                        <b-table
                                            striped
                                            hover
                                            show-empty
                                            :items="donationArr"
                                            :fields="donationFields">
                                            <template>
                                                <h6>Make a donation to support the sport discipline</h6>
                                            </template>
                                            <template #cell(sports_discipline)="row">
                                                {{ listOfDonationSportDiscipline[row.value] }}
                                            </template>
                                            <template #cell(cost)="row">
                                                $ {{ row.value }}
                                            </template>
                                            <template #cell(actions)="row">
                                                <b-button @click="editDonation(row.index)"><i class="fas fa-pencil-alt"></i></b-button>
                                                <b-button @click="removeDonation(row.index)"><i class="fas fa-trash"></i></b-button>
                                            </template>
                                        </b-table>
                                    </div>
                                    <div class="">
                                        <div style="float: right;padding:0 15px 15px 0">
                                            <span>
                                                Total {{ totalAmt }}
                                                <b-badge variant="primary">$</b-badge>
                                            </span>
                                        </div>
                                    </div>
                                </b-card>
                            </div>
                            <div class="col-md-12">
                                <label for="donation-amount" class="form-label">Pay with Card</label>
                                <b-card>
                                    <div  class="row">
                                        <div  class="col-md-12 p-0">
                                            <div  class="form-group">
                                                <label >Card Number<sup  class="text-danger">*</sup></label>
                                                <input type="text" maxlength="16" placeholder="1234 1234 1234 1234" v-model="donationData.cardNumber" class="form-control">
                                                <span class="text-sm text-danger">{{ errors.cardNumber}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div  class="col-md-8 pl-0">
                                            <label >Expiration Date<sup  class="text-danger">*</sup></label>
                                            <div class="row">
                                            <div class="col-md-6 pl-0">
                                            <select id="expiration-month" required="required" v-model="donationData.expiration_month" class="form-control form-select">
                                                <option value="">Month</option>
                                                <option >01</option>
                                                <option >02</option>
                                                <option >03</option>
                                                <option >04</option>
                                                <option >05</option>
                                                <option >06</option>
                                                <option >07</option>
                                                <option >08</option>
                                                <option >09</option>
                                                <option >10</option>
                                                <option >11</option>
                                                <option >12</option>
                                            </select>
                                            <span class="text-sm text-danger">{{ errors.expiration_month}}</span>
                                            </div><div class="col-md-6 p-0">
                                            <select id="expiration-year" required="required" v-model="donationData.expiration_year" class="form-select form-control">
                                                <option value="">Year</option>
                                                <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
                                            </select>
                                            <span class="text-sm text-danger">{{ errors.expiration_year}}</span>
                                            </div>
                                            </div>
                                        </div>
                                        <div  class="col-md-4 p-0">
                                            <label >CVV/CVC<sup  class="text-danger">*</sup></label>
                                            <input maxlength="4" type="password" v-model="donationData.cvv" class="form-control">
                                            <span class="text-sm text-danger">{{ errors.cvv}}</span>
                                        </div>
                                    </div>
                                    <div  class="row pt-3">
                                        <div class="col-md-12 p-0">
                                            <div class="form-group">
                                                <label class="form-label">Name on Card<sup class="text-danger">*</sup></label>
                                                <input type="text" placeholder="William" class="form-control" id="name_on_card" name="donationData.name_on_card" v-model="donationData.name_on_card">
                                                <span class="text-sm text-danger">{{ errors.name_on_card}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-show="paymentError">
                                        <div class="col-md-12">
                                            <p class="text-danger mt-2 mb-0">{{ paymentError }}</p>
                                        </div>
                                    </div>
                                </b-card>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="text-left mt-3 topspace">
                                <button class="btn btn-outline-primary savebtn" @click="redirectToMemberDashboard">Back</button>
                                <button class="btn btn-outline-primary savebtn next-step-btn" v-show="totalAmt>0"
                                        @click="donate">Donate {{ totalAmt }}
                                    <b-badge variant="light">$</b-badge></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 status_bar mt-4">
                    <div class=" right-sidebarlink">
                        <pagesidebar :links="this.getSiteProps('memberdashboard.links')"></pagesidebar>
                    </div>
                </div>
            </div>
            <b-modal id="donation-modal"
                     title="Support your sport?"
                     ref="modal"
                     ok-only
                     ok-title="submit"
                     @hidden="resetModal"
                     @ok="handleOk" centered>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="select-team" class="form-label">Make a donation to the following sport
                            discipline fund</label>
                        <select id="select-team" name="form.donation_discipline" v-model="donation.sports_discipline" class="form-control">
                            <option v-for="(SportDiscipline, id) in sortedDonations(listOfDonationSportDiscipline)"
                                    :value="SportDiscipline.id" :key="id">{{ SportDiscipline.name }}
                            </option>
                        </select>
                        <span class="text-sm text-danger"></span>
                    </div>
                </div>
                <div class="col-md-12">
                    <label for="donation-amount" class="form-label">Donation Amount (USD)</label>
                    <input type="number" maxlength="13" id="donation-amount" name="form.donation_amount"
                           placeholder="10.00" class="form-control" min="1" v-model="donation.cost"
                           @keyup="amountFormat($event)">
                    <span class="text-sm text-danger"></span>
                </div>
            </b-modal>
        </div>
        <!-- <dashboardfooter></dashboardfooter> -->
    </div>
</template>
<script>
// import navigation from "../components/Navigation";
// import sidebar from "../components/Sidebar";
// import dashboardfooter from "../components/Footer";
import pagesidebar from "../components/PageSidebar";
import CustomAlert from "../../../CustomAlert";
import axios from 'axios';

export default {
    name: "makedonation",
    components: { pagesidebar, CustomAlert},
    data(){
        return {
            donation:{
                cost:"",
                sports_discipline:0,
                index:'',
            },
            donationArr:[],
            idx:0,
            totalAmt:0,
            paymentCard:2,
            yearArr:"",
            monthArr:"",
            alertMessage: "",
            displayAlert: "",
            paymentError:"",
            donationFields:[
                {key: 'sports_discipline',sortable: true},
                {key: 'cost',sortable: true, label:"Donation"},
                {key: 'actions',sortable: false},
            ],
            donationData:{
                cardNumber:'',
                expiration_month:'',
                expiration_year:'',
                cvv:'',
                name_on_card: ''
            },
            prop_links: null,
            errors: {
                cardNumber:'',
                expiration_month:'',
                expiration_year:'',
                cvv:'',
                name_on_card: ''
            }
        }
    },
    methods: {
        donate(){
            this.errors = {
                cardNumber:'',
                expiration_month:'',
                expiration_year:'',
                cvv:'',
                name_on_card: ''
            };

            let error_flag = 0;

            if(this.donationData.cardNumber.trim() == ""){
                this.errors.cardNumber = "Card Number is required";
                error_flag++;
            }
            if(this.donationData.expiration_month == ""){
                this.errors.expiration_month = "Month is required";
                error_flag++;
            }
            if(this.donationData.expiration_year == ""){
                this.errors.expiration_year = "Year is required";
                error_flag++;
            }
            if(this.donationData.cvv.trim() == ""){
                this.errors.cvv = "CVV is required";
                error_flag++;
            }
            if(this.donationData.name_on_card.trim() == ""){
                this.errors.name_on_card = "Name on Card is required";
                error_flag++;
            }

            if(error_flag) return;

            if(this.totalAmt<=0){
                console.log("kindly add donations")
            }
            this.showLoader=true;
            this.donationData['discipline']=this.donationArr;
            this.donationData['cost']=this.totalAmt;
            axios.post(this.basePath+"api/member/donate", this.donationData,{ headers:this.memberHeaders })
                .then((response)=>{
                    const resp  = response.data;
                    if(resp.status=='success') {
                        this.donationData={
                            cardNumber:'',
                            expiration_month:'',
                            expiration_year:'',
                            cvv:''
                        };
                        this.donationArr=[];
                        this.totalAmt=0;
                        this.alertMessage = "Thanks for the donation";
                        this.displayAlert = true;
                        this.showLoader=false;
                    }else if(resp.status=='error') {
                        this.paymentError = resp.errors;
                    }
                    //this.redirectToMemberDashboard();
                }).catch((error) =>{
                console.log(error);
            });
        },
        amountFormat() {
            let s = this.donation.cost;
            var rgx = /^[0-9]*\.?[0-9]*$/;
            this.donation.cost = s.match(rgx)[0];
        },
        resetAlert(){
            this.displayAlert = false;
        },
        resetModal() {
            this.donation={
                cost:"",
                sports_discipline:0,
                index:'',
            };
        },
        handleOk(){
            if(this.donation.index==='') {
                this.donation.index=this.idx;
                this.donationArr.push(this.donation);
                this.idx++;
            }else if(this.donation.index!=='') {
                this.donationArr[this.donation.index] = this.donation;
            }
            this.amountCalc();
        },
        editDonation(idx){
            this.donation = this.donationArr[idx];
            this.$nextTick(() => {
                this.$bvModal.show('donation-modal');
            });
        },
        removeDonation(idx){
            this.donationArr.splice(idx, 1);
            this.amountCalc();
        },
        amountCalc(){
            this.totalAmt = this.donationArr.reduce(function(sum, current) {
                return parseInt(sum) + parseInt(current.cost);
            }, 0);
            console.log(this.donationArr);
        }
    },
    mounted() {
        const year = new Date().getFullYear();
        this.yearArr = Array.from({length: 12}, (value, index) => year + index);
    },
    updated(){
        this.prop_links = this.getSiteProps('memberdashboard.links');        
    }
}
</script>
